<template>
  <div class="ModalMap" ref="ModalMap">
    <!-- @on-click="mapClick" -->
    <Chart
      v-loading="isMapLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(51, 51, 51, 0.8)"
      :option="mapOption"
      style="width: 100%; height: calc(98%)"
    ></Chart>
    <el-button
      class="back"
      type="primary"
      v-if="mapName !== 'china'"
      circle
      @click.native="backToUp"
    ></el-button>
    <div class="statistics-box">
      <div class="statistics-box-item">
        <div class="statistics-box-item-name">{{ options && options[0] ? options[0].name : '--' || '--' }}</div>
        <div class="statistics-box-item-num">NO.1</div>
      </div>
      <div class="statistics-box-item">
        <div class="statistics-box-item-name">{{ options && options[1] ? options[0].name : '--' || '--' }}</div>
        <div class="statistics-box-item-num">NO.2</div>
      </div>
      <div class="statistics-box-item">
        <div class="statistics-box-item-name">{{ options && options[2] ? options[0].name : '--' || '--' }}</div>
        <div class="statistics-box-item-num">NO.3</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Chart from "@/components/Chart/Charts";
import china from "@/components/Chart/china";
import CenterMap from "@/components/CenterMap";
import ModelBg2 from "../ModelBg2";
import PiChart from "../../PiChart.vue";

import { getVehicleStart } from "@/api/getData";
// import { getProductTypeIconName } from '@/utils/getProductTypeIconName';
import vehiclePicture from "@/utils/vehiclePicture";
import ElIcon from "../../../../components/Icon/ElIcon.vue";

import { getMapCarNumberInfo } from "@/api/Map/mapRequst.js";

echarts.registerMap("china", china);
export default {
  components: {
    Chart,
    CenterMap,
    ElIcon,
    ModelBg2,
    PiChart,
  },
  props: {
    options: {
      type: Array,
      default: [],
    },
    equipmentData: {
      type: Object,
      default: () => {},
    },
    productTypes: {
      // 产品种类
      type: Array,
      default: [],
    },
    mapName: {
      type: String,
      default: "china",
    },
  },
  data() {
    return {
      mapOption: {}, // 地图配置
      data: [],
      chartMap: "chartMap",
      productList: [], // 产品种类list
      carStatisticsList: [
        {
          label: "作业(台)",
          img: require("../../../../assets/images/bigScreen/carNum3.png"),
          value: 0,
          option: {},
        },
        {
          label: "通电(台)",
          img: require("../../../../assets/images/bigScreen/carNum2.png"),
          value: 0,
          option: {},
        },
        {
          label: "怠速(台)",
          img: require("../../../../assets/images/bigScreen/carNum4.png"),
          value: 0,
          option: {},
        },
        {
          label: "熄火(台)",
          img: require("../../../../assets/images/bigScreen/carNum5.png"),
          value: 0,
          option: {},
        },
        {
          label: "离线(台)",
          img: require("../../../../assets/images/bigScreen/carNum1.png"),
          value: 0,
          option: {},
        },
      ],
      isMapLoading: false,
    };
  },
  watch: {
    options: {
      handler(val) {
        this.isMapLoading = false;
        if (val) {
          this.data = this.options.map((item) => ({
            name: item.name,
            value: item.value,
            code: item.adcode,
          }));
          this.$nextTick(() => {
            this.mapOption = this.getMapOption(this.data);
            // this.getVehicleStartData()
          });
        }
      },
    },

    productTypes: {
      handler(value) {
        this.productList = value;
      },
    },
  },
  created() {
    this.imgUrl = new Image();
    this.imgUrl.src =
      "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/geo.png";
    this.imgUrl.style =
      "position: absolute;left: 10px;right: 0;top: 0;bottom: 0;";
  },
  mounted() {
    this.$nextTick(() => {
      this.mapOption = this.getMapOption(this.data);
    });
  },
  methods: {
    //返回上一级
    async backToUp() {
      this.isMapLoading = true;
      //  this.$refs.mapInstance.goBack();
      //  let info= await getMapCarNumberInfo('中国')
      //  console.log(info.obj);
      this.$emit("backToUp");
    },
    mapChange() {
      this.$refs.mapInstance.map.mapChange();
    },
    provinceShort(name) {
      if (name.startsWith("内蒙")) return "内蒙古";
      if (name.startsWith("黑龙")) return "黑龙江";
      return name.substring(0, 2);
    },
    getMapOption(data) {
      const that = this;
      const mapFeatures = echarts.getMap(this.mapName).geoJson.features;
      const mapData = mapFeatures?.map((item) => {
        return {
          name: item.properties ? item.properties.name : "",
          value: item.properties ? item.properties.childNum : "",
          code: item.properties ? item.properties.adcode : "",
          cp: item.properties
            ? item.properties.cp || item.properties.centroid
            : "",
        };
      });
      const getSize = (res) => {
        const clientWidth = this.$refs["ModalMap"].offsetWidth;
        if (!clientWidth) return;
        const fontSize = 100 * (clientWidth / 1920);
        return res * fontSize;
      };
      // 地图标点图片
      let symbolImg = [
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num1.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num2.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num3.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num4.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num5.png",
      ];
      const convertData = (areaValue) => {
        const res = [];
        for (let i = 0; i < areaValue.length; i++) {
          mapData?.forEach((v) => {
            // if (v.name.indexOf(this.provinceShort(areaValue[i].name)) > -1) {
            if (v.code == areaValue[i].code) {
              const value = v.cp.concat(areaValue[i].value);
              res.push({
                name: areaValue[i].name,
                value: value,
                label: {
                  show: true,
                  position: "top",
                  verticalAlign: "top",
                  align: "center",
                  offset: [0, -sizeW * 47 * 0.5 * 0.05],
                  // padding: 4,
                  // backgroundColor: '#091B59',
                  // borderWidth: 3,
                  // borderColor: '#23E1DB',
                  // borderRadius: 800,
                  distance: -sizeW * 2,
                  formatter(param) {
                    return `{sty1|${param.data.value[2]}}`;
                  },
                  rich: {
                    sty1: {
                      fontSize: 14,
                      //textAlign: 'center',
                      color: "rgba(98, 255, 168, 1)",
                      fontWeight: 600,
                      fontSize: sizeW * 1,
                    },
                  },
                  textStyle: {
                    color: "#ffffff",
                  },
                },
              });
            }
          });
        }
        return res;
      };
      // console.log(convertData(data));
      // 转化已有数据到地图上显示的配置数据
      //   const convertData = (data)=>{
      //   const res = [];
      //   // eslint-disable-next-line no-plusplus
      //   if (data && data.length > 0) {
      //     for (let i = 0; i < data.length; i++) {
      //       mapData.forEach((v) => {
      //         if (v.name == this.provinceShort(data[i].name)) {
      //           const value = v.cp.concat([0]);
      //           res.push({
      //             name: data[i].name,
      //             value: value,
      //             projectName: data[i].value,
      //             label: {
      //               show: true,
      //               position: 'top',
      //               distance: -5,
      //               formatter(param) {
      //                 return `{sty1|${param.data.projectName}}`;
      //               },
      //               rich: {
      //                 sty1: {
      //                   padding: getSize(0.07),
      //                   backgroundColor: 'rgba(7, 28, 38, 0.7)',
      //                   borderWidth: 1,
      //                   borderColor: '#FF771A',
      //                   borderRadius: 2,
      //                   fontSize: getSize(0.14),
      //                 },
      //               },
      //               textStyle: {
      //                 color: '#ffffff',
      //               },
      //             },
      //             emphasis: {
      //               label: {
      //                 show: true,
      //                 position: 'top',
      //                 distance: 0,
      //                 formatter(param) {
      //                   return `{sty1|${param.data.projectName}}`;
      //                 },
      //                 rich: {
      //                   sty1: {
      //                     padding: 7,
      //                     backgroundColor: 'rgba(7, 28, 38, 0.7)',
      //                     borderWidth: 1,
      //                     borderColor: '#FF771A',
      //                     borderRadius: 2,
      //                     fontSize: getSize(0.14),
      //                   },
      //                 },
      //                 textStyle: {
      //                   color: '#ffffff',
      //                 },
      //               },
      //             },
      //           });
      //         }
      //       });
      //     }
      //   }
      //   return res;
      // }
      let regions = data.map((item) => {
        return {
          name: this.provinceShort(item.name),
          // itemStyle: {
          //   normal: {
          //     areaColor: "#D94E5D",
          //     color: "transparent",
          //   },
          // }
        };
      });
      let sizeW = getSize(0.3);
      // let sizeW = 12;
      return {
        // tooltip: {
        //   trigger: 'item',
        //   formatter: '{b}<br/>{c}'
        // },
        visualMap: {
          show: true,
          type: "piecewise",
          hoverLink: false,
          itemGap: 0,
          itemSymbol: "reca",
          inverse: true,
          itemWidth: 40,
          align: "left",
          pieces: [
            {
              max: 100,
              color: "#A994F6",
            },
            {
              min: 100,
              max: 299,
              color: "#A479FB",
            },
            {
              min: 300,
              max: 499,
              color: "#6F35E7",
            },
            {
              min: 500,
              max: 1000,
              color: "#470BC2",
            },
            {
              min: 1000,
              color: "#350596",
            },
          ],
          color: ["#fff"],
          calculable: true,
          textStyle: {
            color: "#fff",
          },
          bottom: 0,
          right: 20,
        },
        // geo: [
        //   {
        //     map: this.mapName,
        //     show: this.mapName === "china" ? false : true,
        //     roam: false, //不开启缩放和平移
        //     zoom: this.mapName === "china" ? 1.25 : 1, //视角缩放比例
        //     bottom: 10,
        //     // itemStyle: {
        //     //   normal: {
        //     //     areaColor: "#073B7E",
        //     //     borderColor: "#0cabde" // 省市边界线
        //     //   },
        //     //   emphasis: {
        //     //     show: false,
        //     //     disabled: true,
        //     //     areaColor: '#9DCCFF',//鼠标滑过区域颜色
        //     //     label: {
        //     //       show: true,
        //     //       textStyle: {
        //     //         color: '#fff'
        //     //       }
        //     //     }
        //     //   }
        //     // },
        //     // aspectScale: 0.9,
        //     roam: false, //是否允许缩放
        //     layoutSize: "95%",
        //     layoutCenter: ["50%", "50%"],
        //     label: {
        //       normal: {
        //         show: false, //地区名称
        //         fontSize: sizeW * 0.9,
        //         color: "#ffffff",
        //       },
        //       emphasis: {
        //         textStyle: {
        //           color: "rgba(243, 248, 254, 1)",
        //         },
        //       },
        //     },
        //     itemStyle:
        //       this.mapName === "china"
        //         ? {}
        //         : {
        //             // areaColor: "#0530a7",
        //             borderColor: "rgba(88, 155, 237, 1)", // 省市边界线
        //             // borderWidth: 2,,
        //             color: {
        //               image: this.imgUrl,
        //               repeat: "repeat",
        //             },
        //             emphasis: {
        //               color: "#003dea", //移入后的颜色
        //             },
        //           },
        //     emphasis: {
        //       itemStyle:
        //         this.mapName === "china"
        //           ? {}
        //           : {
        //               color: {
        //                 image: this.imgUrl,
        //                 repeat: "repeat",
        //               },
        //             },
        //       label: {
        //         show: 0,
        //         color: "#fff",
        //       },
        //     },
        //     zlevel: 3,
        //     regions: [...regions, { name: '南海诸岛',  value: 0,  itemStyle: {opacity: 0,}}],
        //   },
        // ],
        series:
          this.mapName === "china"
            ? [
                {
                  type: "map",
                  map: "china",
                  zoom: 1.152,
                  // top: '30',
                  zlevel: 1,
                  left: "center",
                  align: "right",
                  label: {
                    normal: {
                      show: true,
                      color: "#fff",
                      fontSize: sizeW * 1,
                    },
                    emphasis: {
                      // show: false,
                    },
                  },
                  itemStyle: {
                    normal: {
                      borderColor: "#a7e4e6", //省市边界线
                      shadowColor: "rgba(166, 230, 236, 0)",
                      areaColor: {
                        image: this.imgUrl,
                        repeat: "repeat",
                      },
                      shadowOffsetX: 0,
                      shadowOffsetY: 0,
                      shadowBlur: 120,
                    },
                    emphasis: {
                      // label: {
                      //   color: "#fff",
                      // },
                      // areaColor: "#4aafde",
                      // borderColor: "#fff",
                    },
                  },
                    emphasis: {
                    disabled: true,
                    },
                  select: {
                    disabled: true,
                  },
                  data: data.map((val) => {
                    let ind =
                      val.name.indexOf("省") > -1 ||
                      val.name.indexOf("市") > -1 ||
                      val.name.indexOf("自治区") > -1 ||
                      val.name.indexOf("维吾尔") > -1 ||
                      val.name.indexOf("壮族自治区") > -1;
                    return {
                      ...val,
                      name: ind
                        ? val.name.substr(
                            0,
                            val.name.indexOf("省") > -1
                              ? val.name.indexOf("省")
                              : val.name.indexOf("维吾尔") > -1
                              ? val.name.indexOf("维吾尔")
                              : val.name.indexOf("壮族自治区") > -1
                              ? val.name.indexOf("壮族自治区")
                              : val.name.indexOf("市") > -1
                              ? val.name.indexOf("市")
                              : val.name.indexOf("自治区")
                          )
                        : val.name,
                    };
                  }),
                },
              ]
            : [
                {
                  name: "点",
                  type: "scatter",
                  coordinateSystem: "geo",
                  legendHoverLink: false,
                  label: {
                    normal: {
                      show: true,
                      formatter: function (obj) {
                        return obj.data.value[2];
                      },
                      textStyle: {
                        color: "#000000",
                      },
                    },
                  },
                  symbol: function (params) {
                    //   return symbolImg[params[2].toString().length - 1];
                    // return 'image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/mapkhName.png'
                    return that.mapName === "china"
                      ? ""
                      : "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/mapsbName.png";
                  },
                  symbolSize: function (params) {
                    let size = [
                      that.mapName === "china" ? 0 : sizeW * 165 * 0.05,
                      that.mapName === "china" ? 0 : sizeW * 58 * 0.05,
                    ];
                    //   switch (params[2].toString().length) {
                    //     case 1:
                    //       size = [(sizeW * 2), (sizeW * 3.61)];
                    //       break;
                    //     case 2:
                    //       size = [(sizeW * 2.198), (sizeW * 3.61)];
                    //       break;
                    //     case 3:
                    //       size = [(sizeW * 3.333), (sizeW * 3.61)];
                    //       break;
                    //     case 4:
                    //       size = [(sizeW * 3.333), (sizeW * 3.61)];
                    //       break;
                    //     case 5:
                    //       size = [(sizeW * 4.18), (sizeW * 3.61)];
                    //       break;
                    //     default:
                    //       size = [(sizeW * 4.18), (sizeW * 3.61)];
                    //       break;
                    //   }
                    return size;
                  },
                  symbolOffset: [
                    that.mapName === "china" ? 0 : sizeW * 3,
                    sizeW * -0.3,
                  ],
                  // ../../assets/screen/position-map.png
                  itemStyle: {
                    normal: {
                      borderColor: "#EDF4FD",
                      color: "#4BE1FF", // 标志颜色
                    },
                  },
                  emphasis: {
                    scale: false,
                  },
                  selectedMode: false,
                  zlevel: 6,
                  data: convertData(data),
                },
              ],
      };
    },

    clickTooltip(item) {
      return !item.disabled;
    },

    // 获取icon Name
    vehicleIconName(item) {
      console.log(item);
      return vehiclePicture(item.dictType, item.dictValue);
    },

    // 获取当前点击的省份数据分布
    async mapClick(data = "") {
      if (this.mapName !== "china") {
        return false;
      }
      this.isMapLoading = true;
      const { name } = data;
      this.$emit("mapChang", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.chartpi {
  height: 20px;
  width: auto;
}
.show-proportion {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../../assets/images/bigScreen/products/showProportionBg.png");
  &-item {
    display: flex;
    align-items: center;
    margin: 0 0.42rem;
    &-statistics {
      margin-left: 0.2rem;
      &-num {
        font-weight: bold;
        font-size: 0.34rem;
        background-image: -webkit-linear-gradient(
          90deg,
          #015eea 0%,
          #00c0fa 100%
        );
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
      &-text {
        font-size: 0.14rem;
        color: #ffffff;
      }
      &-unit {
        font-size: 0.24rem;
      }
    }
    &-icon {
      width: 0.61rem;
      height: 0.63rem;
    }
  }
}
.topTitle {
  // z-index: 9;
  // position: absolute;
  height: 14.3%;
  width: 100%;
  .carTotal-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    > div {
      width: 58px;
      display: flex;
      flex-direction: column;
      color: #ffffff;
      font-size: 0.14rem;
      text-align: center;
      height: 100%;
      > div {
        position: relative;
        flex: 1;
        > img {
          position: absolute;
          height: 100%;
          right: 50%;
          transform: translateX(50%);
          top: 0;
        }
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 0.11rem;
        }
      }
    }
  }
}
.back {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 32px;
  height: 32px;
  background: rgba(0, 138, 255, 0.16);
  border: 1px solid #008aff;
  background-image: url("../../../../assets/screen/back.png");
  background-repeat: no-repeat;
  background-position: center;
}
.ModalMap {
  width: 100%;
  height: 100%;
  position: relative;
  // display: flex;
  // align-items: center;
  .identification-box {
    position: absolute;
    top: 25px;
    left: 20px;
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      img {
        width: 0.46rem;
        height: 0.48rem;
      }
      span {
        font-size: 0.16rem;
        font-weight: bold;
        color: #ffffff;
        margin-left: 0.08rem;
      }
    }
  }
  .statistics-box {
    position: absolute;
    bottom: 25px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &-item {
      width: 0.74rem;
      height: 0.88rem;
      font-weight: bold;
      font-size: 0.14rem;
      color: #FFFFFF;
      margin-right: 0.4rem;
      background-image: url("../../../../assets/images/bigScreen/newMechanical/cityRanking.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      &-name {
        text-align: center;
        margin-top: 0.57rem;
      }
      &-num {
        text-align: center;
        margin-top: 0.09rem;
      }
    }
  }
}
.info-content {
  width: 20%;
}
.el-empty {
  margin: 20px 0;
}
.top-tow {
  width: 100%;
  height: 88%;
  overflow: auto;
  color: #ffffff;
  line-height: 30px;
  .item {
    display: flex;
    flex-flow: row;
    img {
      height: 20px;
      margin: 8px 5px;
    }
    label {
      width: 52%;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 5px;
    }
  }
}
::v-deep .el-loading-text {
  color: #19bafd;
  font-size: 16px;
  font-weight: bold;
}
::v-deep .el-icon-loading {
  color: #19bafd;
  font-size: 26px;
  font-weight: bold;
}
</style>